import { FormEvent, useState } from "react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { getLoginErrorAlert as LoginErrorAlert } from "../ErrorHandler/ErrorHandler";
import { Send } from "lucide-react";
import { ApiError } from "src/types/errors.types";
import { TIENDANUBE_AUTH_LINK } from "src/constants";
import { ApiClient } from "src/types/ApiClient";

export default function LoginCard({
  onSuccess,
  apiClient,
}: {
  onSuccess: (email: string) => void;
  apiClient: ApiClient;
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [apiError, setApiError] = useState<ApiError | undefined>();
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!email) {
      return;
    }
    setShowLoader(true);

    const { error } = await apiClient.login(email);
    setShowLoader(false);
    if (error) {
      setApiError(error);
      return;
    }
    onSuccess(email);
  };

  return (
    <div className="max-w-sm rounded-lg shadow-lg bg-white p-6 space-y-6 border border-gray-200 dark:border-gray-700">
      <div className="space-y-2 text-center">
        <h1 className="text-3xl font-bold">{t(`loginCard.head`)}</h1>
        <Alert severity="info">{t(`loginCard.userNotice`)}</Alert>
        {apiError && <LoginErrorAlert apiError={apiError!} />}
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`loginCard.preTermsAndConditions`)}
          <a className="text-blue-500 hover:text-blue-700" href="/terms">
            {" "}
            {t(`loginCard.terms`)}
          </a>{" "}
          {t(`loginCard.and`)}{" "}
          <a className="text-blue-500 hover:text-blue-700" href="/privacy">
            {t(`loginCard.privacy`)}
          </a>
          .
        </p>
      </div>
      <div>
        <form className="space-y-2" id="form">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            placeholder="m@example.com"
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            disabled={showLoader || !email}
            className="w-full text-white"
            onClick={handleSubmit}
            type="submit"
          >
            <div className="flex items-center justify-center">
              {showLoader ? (
                <div className="animate-spin h-6 w-6 rounded-full border-t-4 border-gray-900 mr-2" />
              ) : (
                <Send size="16" className="mr-2" />
              )}
              {t(`loginCard.sendToken`)}
            </div>
          </Button>
        </form>
        <div className="mt-6 flex justify-center">
          <Button
            variant="link"
            onClick={() => (window.location.href = TIENDANUBE_AUTH_LINK)}
          >
            {t("loginCard.signUpButton")}
          </Button>
        </div>
      </div>
    </div>
  );
}
