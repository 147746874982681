import { useLocation, useNavigate } from "react-router-dom";
import { Page } from "../../types/pagination.types";
import NavBar from "../NavBar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import Pagination from "../Pagination";
import useQuery from "../../hooks/useQuery";
import { useEffect, useState } from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { getFormattedDate } from "src/lib/utils";
import { useTranslation } from "react-i18next";
import {
  Plus,
  Rocket,
  QrCode,
  FilePenLine,
  EllipsisVertical,
  Trash2,
  Group,
  Link,
  Copy,
} from "lucide-react";
import Loader from "../Loader";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import "react-toastify/dist/ReactToastify.css";
import QrModal from "./QrModal";
import Modal from "./Modal";
import { ApiError } from "src/types/errors.types";
import { getBundleErrorPage as BundleErrorPage } from "../ErrorHandler/ErrorHandler";
import { ApiClient } from "src/types/ApiClient";
import { BundlePreviewDTO } from "src/types/bundle.types";
import WhatsAppButton from "../WhatsAppButton";
import { Typography } from "@mui/material";

const PAGE_SIZE = 10;

const INIT_PAGE = {
  data: [],
  pagination: {
    offset: 0,
    pageSize: 0,
    totalCount: 0,
  },
};

export default function BundleListViewPage({
  apiClient,
}: {
  apiClient: ApiClient;
}) {
  const query = useQuery();
  const token = useAuthHeader();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [bundleList, setBundleList] =
    useState<Page<BundlePreviewDTO>>(INIT_PAGE);
  const [showLoader, setShowLoader] = useState(false);
  const [editBundleProduct, setEditBundleProduct] = useState(false);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offset = parseInt(searchParams.get("offset") || "") || 0;

  useEffect(() => {
    const fetchBundles = async () => {
      if (!token) {
        return;
      }
      setShowLoader(true);
      const { bundles, error } = await apiClient.getBundles(
        PAGE_SIZE,
        offset,
        token,
      );
      setShowLoader(false);
      if (error || !bundles) {
        setApiError(error!);
        setBundleList(INIT_PAGE);
      }
      setBundleList(bundles!);
    };

    void fetchBundles();
  }, [apiClient, token, offset]);

  if (showLoader) {
    return <Loader />;
  }

  const handleChangeStatusBundle = async (bundle: BundlePreviewDTO) => {
    if (!token) {
      return;
    }

    const { error } =
      bundle.status === "ACTIVE"
        ? await apiClient.deleteBundle(bundle.slug, token)
        : await apiClient.activateBundle(bundle.slug, token);

    if (error) {
      setApiError(error);
      return;
    }
    window.location.reload();
  };

  if (apiError) {
    return <BundleErrorPage apiError={apiError!} />;
  }

  return (
    <>
      <NavBar />
      {token && !editBundleProduct && (
        <WhatsAppButton
          whatsappNumber="5491165142615"
          message="Hola! Escribo desde la tienda:"
        />
      )}
      <Dialog open={editBundleProduct}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("bundleEditor.editProduct")}</DialogTitle>
          </DialogHeader>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {t("bundleList.editDisclaimer")}
          </Typography>
          <DialogFooter>
            <Button
              type="button"
              variant="default"
              onClick={() => setEditBundleProduct(false)}
            >
              OK
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="bg-white mt-10">
        <div className="relative w-full overflow-auto mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <table className="w-full caption-bottom text-sm">
            <thead className="[&amp;_tr]:border-b">
              <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                  {t("bundleList.date")}
                </th>
                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                  {t("bundleList.name")}
                </th>
                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                  {t("bundleList.status")}
                </th>
                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0">
                  {t("bundleList.withProduct")}
                </th>
                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&amp;:has([role=checkbox])]:pr-0"></th>
              </tr>
            </thead>
            <tbody className="[&amp;_tr:last-child]:border-0">
              {bundleList &&
                bundleList.data.map((bundle) => (
                  <BundleRow
                    key={bundle.slug}
                    bundle={bundle}
                    statusHandler={handleChangeStatusBundle}
                    setEditBundleProduct={setEditBundleProduct}
                  />
                ))}
            </tbody>
          </table>
          {bundleList.pagination.totalCount >= 0 &&
            bundleList.pagination.totalCount > PAGE_SIZE && (
              <Pagination
                paginationMetadata={{
                  ...bundleList.pagination,
                  pageSize: PAGE_SIZE,
                }} //FixMe: Bundle API Change the pageSize and then prev page button dont work as expected.
                queryParams={query}
              />
            )}
          {bundleList && bundleList.data && bundleList.data.length === 0 ? (
            <div className="flex flex-col justify-center">
              <h2 className="mb-2 text-center text-gray-400">
                {t("bundleList.firstBundleText")}
              </h2>
              <Button
                onClick={() => navigate("/bundle/create")}
                className="mt-4 mb-2 mx-auto"
              >
                {t("bundleList.firstBundleButton")}
              </Button>
            </div>
          ) : (
            <div className="flex justify-end mr-10">
              <Button
                variant="secondary"
                className="flex mb-1"
                onClick={() => navigate("/bundle/create")}
              >
                {t("bundleList.add")}
                <Plus className="pl-2" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function BundleRow({
  bundle,
  statusHandler,
  setEditBundleProduct,
}: {
  bundle: BundlePreviewDTO;
  statusHandler: (bundle: BundlePreviewDTO) => Promise<void>;
  setEditBundleProduct: (value: boolean) => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onlineLabel = (
    <span className="px-2 py-1 bg-green-200 text-green-800 rounded-md">
      {t("bundleList.online")}
    </span>
  );

  const offlineLabel = (
    <span className="px-2 py-1 bg-slate-200 text-slate-800 rounded-md">
      {t("bundleList.offline")}
    </span>
  );

  const buildingLabel = (
    <span className="px-2 py-1 bg-yellow-200 text-slate-800 rounded-md">
      {t("bundleList.building")}
    </span>
  );

  const errorLabel = (
    <span className="px-2 py-1 bg-orange-200 text-slate-800 rounded-md">
      {t("bundleList.error")}
    </span>
  );

  const statusActionName =
    bundle.status === "ACTIVE" ? "delete" : "activate";

  return (
    <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
      <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
        {getFormattedDate(bundle.createdOn)}
      </td>
      <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
        {bundle.name}
      </td>
      <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
        {bundle?.status === "ACTIVE"
          ? onlineLabel
          : bundle?.status === "DISABLE"
            ? offlineLabel
            : bundle?.status === "BUILDING"
              ? buildingLabel
              : errorLabel}
      </td>
      <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
        {bundle.bundleType === "BUNDLE_PRODUCT" ? (
          <div className="flex flex-row">
            <Group size={16} style={{ marginRight: "4%" }} /> <Link size={16} />
          </div>
        ) : (
          <Link size={16} />
        )}
      </td>
      <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
        <Popover>
          <PopoverTrigger>
            <Button
              className="px-2 py-1 bg-transparent text-black hover:bg-gray-200 active:bg-gray-300 rounded"
              type="button"
            >
              <EllipsisVertical size="16" className="w-4 h-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-40 space-y-2">
            <CopyToClipboard
              text={bundle.contentUrl}
              onCopy={() => toast(t("bundleList.copied"))}
            >
              <Button variant="secondary" size="fw" className="justify-start">
                <Copy size="16" className="w-4 h-4" />
                <span className="text-sm font-medium">
                  {t("bundleList.share")}
                </span>
                <ToastContainer />
              </Button>
            </CopyToClipboard>
            <Dialog>
              <DialogTrigger>
                <Button
                  variant="secondary"
                  size="fw"
                  className="justify-start"
                  disabled={bundle.status !== "ACTIVE"}
                >
                  <QrCode size="16" className="w-4 h-4" />
                  <span className="text-sm font-medium">
                    {t("bundleList.qr")}
                  </span>
                </Button>
              </DialogTrigger>
              <QrModal qrUrl={bundle.qrUrl} bundleName={bundle.name} />
            </Dialog>
            <Button
              variant="secondary"
              className="justify-start"
              size="fw"
              disabled={bundle.status === "BUILDING"}
              onClick={() => navigate(`/bundle/${bundle.slug}/edit`)}
            >
              <FilePenLine size="16" className="w-4 h-4" />
              <span className="text-sm font-medium">
                {t("bundleList.edit")}
              </span>
            </Button>
            <Dialog>
              <DialogTrigger>
                <Button
                  variant="secondary"
                  size="fw"
                  className="justify-start"
                  disabled={bundle.status === "BUILDING"}
                >
                  {bundle.bundleType === "BUNDLE_PRODUCT" ? (
                    <Trash2 size="16" className="w-4 h-4" />
                  ) : (
                    <Rocket size="16" className="w-4 h-4" />
                  )}
                  <span className="text-sm font-medium">
                    {t(`bundleList.${statusActionName}`)}
                  </span>
                </Button>
              </DialogTrigger>
              <Modal
                bundle={bundle}
                severity={
                  bundle.status === "ACTIVE" ? "error" : "success"
                }
                name={`${statusActionName}`}
                handler={statusHandler}
              />
            </Dialog>
          </PopoverContent>
        </Popover>
      </td>
    </tr>
  );
}
