import NavBar from "../NavBar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import boxImage from "../../resources/box.png";
import Footer from "../Footer";
import { Card, CardContent } from "../ui/card";
import { Package, Percent, QrCode } from "lucide-react";

export default function LandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-100/50">
      <NavBar />

      <main>
        {/* Hero Section */}
        <section className="container px-4 py-16 md:py-24">
          <div className="grid gap-12 md:grid-cols-2 md:items-center">
            <div className="space-y-6">
              <h1 className="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
                {t("landing.h1")}
              </h1>
              <p className="text-xl text-muted-foreground">
                {t("landing.sub_h1")}
              </p>
              <div className="flex gap-4">
                <Button size="lg" onClick={() => navigate("/bundle/create")}>
                  {t("landing.main_cta")}
                </Button>
                <a href="https://www.youtube.com/watch?v=XJw_xoeOD2o">
                  <Button size="lg" variant="outline">
                    {t("landing.demo_txt")}
                  </Button>
                </a>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-4 bg-gradient-to-r from-blue-500/10 to-purple-500/10 blur-3xl" />
              <img
                src={boxImage}
                alt="Bundle illustration"
                className="relative mx-auto w-full max-w-lg"
              />
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="container mx-auto px-4 py-16">
          <h2 className="mb-12 text-center text-3xl font-bold">
            {t("landing.h2")}
          </h2>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            <Card>
              <CardContent className="pt-6">
                <div className="mb-4 inline-flex rounded-lg bg-primary/10 p-3">
                  <Package className="h-6 w-6 text-primary" />
                </div>
                <h3 className="mb-2 text-xl font-bold">
                  {t("landing.inventory_sync_feature_header")}
                </h3>
                <p className="text-muted-foreground">
                  {t("landing.inventory_sync_feature_p")}
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <div className="mb-4 inline-flex rounded-lg bg-primary/10 p-3">
                  <Percent className="h-6 w-6 text-primary" />
                </div>
                <h3 className="mb-2 text-xl font-bold">
                  {t("landing.discount_feature_header")}
                </h3>
                <p className="text-muted-foreground">
                  {t("landing.discount_feature_p")}
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <div className="mb-4 inline-flex rounded-lg bg-primary/10 p-3">
                  <QrCode className="h-6 w-6 text-primary" />
                </div>
                <h3 className="mb-2 text-xl font-bold">
                  {t("landing.quick_checkout_feature_header")}
                </h3>
                <p className="text-muted-foreground">
                  {t("landing.quick_checkout_feature_p")}
                </p>
              </CardContent>
            </Card>
          </div>
        </section>

        {/* Stats Section */}
        <section className="container mx-auto px-4 py-16">
          <div className="rounded-xl bg-gradient-to-r from-primary/90 to-primary p-8 text-white md:p-12">
            <div className="grid gap-8 md:grid-cols-3">
              <div className="text-center">
                <div className="mb-2 text-4xl font-bold">30%</div>
                <div className="text-primary-foreground">
                  {t("landing.orders_stat_p")}
                </div>
              </div>
              <div className="text-center">
                <div className="mb-2 text-4xl font-bold">50+</div>
                <div className="text-primary-foreground">
                  {t("landing.merchant_stat_p")}
                </div>
              </div>
              <div className="text-center">
                <div className="mb-2 text-4xl font-bold">24/7</div>
                <div className="text-primary-foreground">
                  {t("landing.stock_stat_p")}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="container mx-auto px-4 py-16">
          <div className="text-center">
            <h2 className="mb-4 text-3xl font-bold">
              {t("landing.cta_section_h")}
            </h2>
            <p className="mb-8 text-xl text-muted-foreground">
              {t("landing.cta_section_p")}
            </p>
            <Button size="lg" onClick={() => navigate("/bundle/create")}>
              {t("landing.cta_button")}
            </Button>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
