import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import logo from "../../resources/logo.png";
import { Button } from "../ui/button";

const navigation = [
  { name: "explore", href: "/explore", current: false, private: true },
  { name: "create", href: "/bundle/create", current: false, private: true },
  { name: "pricing", href: "/pricing", current: false, private: false },
  { name: "help", href: "/help", current: false },
  { name: "login", href: "/login", current: false, private: false },
  { name: "logout", href: "/", current: false, private: true },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  return (
    <div className="flex items-center ml-2">
      <Select
        defaultValue={i18n.language.substring(0, 2)}
        onValueChange={(value) => i18n.changeLanguage(value)}
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="es">ES</SelectItem>
          <SelectItem value="pr">PR</SelectItem>
          <SelectItem value="en">EN</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};

export default function NavBar() {
  const signOut = useSignOut();
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-800 hover:bg-gray-100 hover:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <NavLink to="/" className="flex items-center">
                    <img src={logo} alt="Bundle logo" width="120px" />
                  </NavLink>
                </div>
                <div className="flex hidden sm:ml-auto sm:flex space-x-4">
                  <div className="flex font-bold">
                    {navigation
                      .filter((item) => {
                        return !isAuthenticated()
                          ? item.private === undefined ||
                              item?.private === false
                          : item.private === undefined ||
                              item?.private === true;
                      })
                      .map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="text-gray-900 hover:underline underline-offset-2 decoration-cyan-900 hover:text-cyan-900 rounded-md px-3 py-2"
                          aria-current={item.current ? "page" : undefined}
                          onClick={() =>
                            item.name === "logout" ? signOut() : null
                          }
                        >
                          <Button
                            variant={
                              item.name === "logout" || item.name === "login"
                                ? "default"
                                : "ghost"
                            }
                          >
                            {t(`navBar.${item.name}`)}
                          </Button>
                        </a>
                      ))}
                  </div>
                  <LanguageDropdown />
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation
                .filter((item) => {
                  return !isAuthenticated()
                    ? item.private === undefined || item?.private === false
                    : item.private === undefined || item?.private === true;
                })
                .map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-200 text-grey"
                        : "text-gray-900 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium",
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {t(`navBar.${item.name}`)}
                  </Disclosure.Button>
                ))}
            </div>
            <LanguageDropdown />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
